export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home',
  },
  {
    text: 'Kundenverwaltung',
    icon: 'group',
    items: [
      {
        text: 'Kundensuche',
        path: '/customers',
      },
    ],
  },
  {
    text: 'Fahrerbüro',
    icon: 'importselected',
    items: [
      {
        text: 'Touren',
        path: '/driversoffice',
      },
      {
        text: 'Offene Lieferscheine',
        path: '/driversoffice/delivery/open',
      },
    ],
  },
  {
    text: 'Buchhaltung',
    icon: 'folder',
    items: [
      {
        text: 'Rechnungen',
        path: '/invoices/list',
      },
      {
        text: 'Lieferscheine',
        path: '/delivery/list',
      },
      {
        text: 'Export',
        path: '/accounting/export',
      },
    ],
  },
  {
    text: 'Abrechnung',
    icon: 'money',
    items: [
      {
        text: 'Zahlarten Übersicht',
        path: '/invoicing/paymentreport',
      },

      {
        text: 'ARAL EDI Abrechnung',
        path: '/invoicing/araledi',
      },
      {
        text: 'Provisionen MG',
        path: '/invoicing/provision',
      },
    ],
  },

  {
    text: 'Dispo',
    icon: 'car',
    items: [
      {
        text: 'Kalender',
        path: '/dispo/',
      },
      {
        text: 'Touren',
        path: '/tours',
      },
      {
        text: 'Fahrer',
        path: '/drivers',
      },
    ],
  },
  {
    text: 'Auswertungen',
    icon: 'datatrending',
    items: [
      {
        text: 'Artikelstatistik',
        path: '/stats/articles',
      },
      {
        text: 'Wochenstatistik',
        path: '/stats/week',
      },
      {
        text: 'NonFood',
        path: '/stats/nonfood',
      },
      {
        text: 'Grusskarten Fahrer',
        path: '/stats/cards',
      },
    ],
  },
  {
    text: 'Verwaltung',
    icon: 'toolbox',
    items: [
      {
        text: 'Artikelpflege',
        path: '/articles',
      },
      {
        text: 'Artikelgruppe',
        path: '/articlegroups',
      },
      {
        text: 'Preisgruppen',
        path: '/pricegroups',
      },
      {
        text: 'Steuercodes',
        path: '/taxes',
      },
    ],
  },
];
